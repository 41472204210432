import { Link } from '@chakra-ui/next-js';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { navLinks } from '@/shared/constants';
import { BurgerIcon, CloseIcon, SelectAccordion } from '@/shared/ui';

const MobileMenu = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <IconButton
        aria-label='Open menu button'
        gridArea='menu'
        icon={<BurgerIcon boxSize={6} />}
        variant='iconButton'
        onClick={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        placement='right'
        size='xs'
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent overflowY='auto'>
          <DrawerHeader
            as='div'
            display='flex'
            justifyContent='end'
            px='md'
          >
            <IconButton
              aria-label='Close menu button'
              icon={<CloseIcon boxSize={6} />}
              variant='iconButton'
              onClick={onClose}
            />
          </DrawerHeader>
          <DrawerBody
            as='nav'
            display='flex'
            flexDirection='column'
            gap='4'
            pt='lg'
          >
            {navLinks.map(({ title, items, href }) => {
              if (items) {
                return (
                  <SelectAccordion
                    key={title}
                    list={items}
                    title={title}
                  />
                );
              }
              return (
                <Link
                  key={title}
                  href={href!}
                >
                  {title}
                </Link>
              );
            })}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MobileMenu;
